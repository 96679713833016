.App {
  font-family: sans-serif;
}

textarea {
  font-size: 1.2em;
}

button {
  padding: 2em 5em;
}
